<template>
  <HXContentCard title="Hoja de Vida" :defaultToBack="personaId">
    <div class="p-4">
      <!-- tabs por categorias -->
      <div class="buttons btn-group">
        <b-button
          v-for="item in tabsGroup"
          :key="item.id"
          :variant="tabs == item.id ? 'primary' : 'dark'"
          @click="activeTab(item.id)"
          size="lg"
          type="button"
        >
          <i v-if="tabs == item.id" class="fas fa-check-circle"></i>
          <strong>{{ item.name }}</strong>
        </b-button>
      </div>

      <!-- datos personales -->
      <tab_datos_personales
        :tabs="tabs"
        :persona="persona"
        :informacionPersonal="informacionPersonal"
        :listaTipoDocumento="listaTipoDocumento"
        :listaCategoriaLicencia="listaCategoriaLicencia"
        @submitForm="sendDataPersonalInformation"
      />

      <!-- educacion formal -->
      <tab_educacion_formal
        :tabs="tabs"
        :educacionFormal="educacionFormal"
        :dataLocation="dataLocation"
        :isCreated="isCreated"
        :listaPaises="listaPaises"
        :newEducacionFormal="newEducacionFormal"
        :NivelesAcademicos="NivelesAcademicos"
        @sendUpdateFormalEducation="sendUpdateFormalEducation"
        @deleteItem="deleteItem"
        @createFormalEducation="createFormalEducation"
        @getIdForData="getIdForData"
        @getDepartmentoPaisList="getDepartmentoPaisList"
        @actualizaMunicipios="actualizaMunicipios"
        @initDataInfoEducationFormal="initDataInfoEducationFormal"
        @closeCreate="closeCreate"
      />

      <!-- educacion informal -->
      <tab_educacion_informal
        :tabs="tabs"
        :educacionNoFormal="educacionNoFormal"
        :dataLocation="dataLocation"
        :isCreatedInformal="isCreatedInformal"
        :listaPaises="listaPaises"
        :newEducacionInformal="newEducacionInformal"
        @sendUpdateInformalEducation="sendUpdateInformalEducation"
        @deleteItem="deleteItem"
        @createInformalEducation="createInformalEducation"
        @getIdForData="getIdForData"
        @getDepartmentoPaisList="getDepartmentoPaisList"
        @actualizaMunicipios="actualizaMunicipios"
        @initDataInfoEducationInformal="initDataInfoEducationInformal"
        @closeCreateInformal="closeCreateInformal"
      />

      <!-- experiencia profesional -->
      <tab_experiencia_profesional
        :tabs="tabs"
        :experienciaProfesional="experienciaProfesional"
        :dataLocation="dataLocation"
        :isCreatedProfessionalExperience="isCreatedProfessionalExperience"
        :listaPaises="listaPaises"
        :newExperienciaProfesional="newExperienciaProfesional"
        @sendUpdateProfessionalExperience="sendUpdateProfessionalExperience"
        @deleteItem="deleteItem"
        @createProfessionalExperience="createProfessionalExperience"
        @getIdForData="getIdForData"
        @getDepartmentoPaisList="getDepartmentoPaisList"
        @actualizaMunicipios="actualizaMunicipios"
        @initDataInfoProfessionalExperience="initDataInfoProfessionalExperience"
        @closeCreateProfessionalExperience="closeCreateProfessionalExperience"
      />

      <!-- informacion familiar -->
      <tab_informacion_familiar
        :tabs="tabs"
        :informacionFamiliar="informacionFamiliar"
        :dataLocation="dataLocation"
        :isCreatedFamilyInformation="isCreatedFamilyInformation"
        :listaPaises="listaPaises"
        :Parentesco="Parentesco"
        :newFamilyInformation="newFamilyInformation"
        @sendUpdateFamilyInformation="sendUpdateFamilyInformation"
        @deleteItem="deleteItem"
        @createFamilyInformation="createFamilyInformation"
        @getIdForData="getIdForData"
        @getDepartmentoPaisList="getDepartmentoPaisList"
        @actualizaMunicipios="actualizaMunicipios"
        @initDataInfoFamilyInformation="initDataInfoFamilyInformation"
        @closeCreateFamilyInformation="closeCreateFamilyInformation"
      />

      <!-- referencias personales -->
      <tab_referencias_personales
        :tabs="tabs"
        :referenciasPersonales="referenciasPersonales"
        :dataLocation="dataLocation"
        :isCreatedPersonalReferences="isCreatedPersonalReferences"
        :listaPaises="listaPaises"
        :newPersonalReferences="newPersonalReferences"
        @sendUpdatePersonalReferences="sendUpdatePersonalReferences"
        @deleteItem="deleteItem"
        @createPersonalReferences="createPersonalReferences"
        @getIdForData="getIdForData"
        @getDepartmentoPaisList="getDepartmentoPaisList"
        @actualizaMunicipios="actualizaMunicipios"
        @initDataInfoPersonalReferences="initDataInfoPersonalReferences"
        @closeCreatePersonalReferences="closeCreatePersonalReferences"
      />
    </div>
  </HXContentCard>
</template>

<script>
import tab_datos_personales from "./tab_datos_personales.vue";
import tab_educacion_formal from "./tab_educacion_formal.vue";
import tab_educacion_informal from "./tab_educacion_informal.vue";
import tab_experiencia_profesional from "./tab_experiencia_profesional.vue";
import tab_informacion_familiar from "./tab_informacion_familiar.vue";
import tab_referencias_personales from "./tab_referencias_personales.vue";
import misMetodos from "./misMetodos";

export default {
  name: "hoja_vida",
  components: {
    tab_datos_personales,
    tab_educacion_formal,
    tab_educacion_informal,
    tab_experiencia_profesional,
    tab_informacion_familiar,
    tab_referencias_personales,
  },
  data() {
    return {
      tabs: 1,
      persona: {},
      tabsGroup: [
        { id: 1, name: "Datos Personales" },
        { id: 2, name: "Educación Formal" },
        { id: 3, name: "Educación Informal" },
        { id: 4, name: "Experiencia Profesional" },
        { id: 5, name: "Informacion Familiar" },
        { id: 6, name: "Referencias Personales" },
      ],

      validateExistence: [],
      informacionPersonal: {},
      educacionFormal: [],
      educacionNoFormal: [],
      experienciaProfesional: [],
      informacionFamiliar: [],
      referenciasPersonales: [],
      newEducacionFormal: {},
      newEducacionInformal: {},
      newExperienciaProfesional: {},
      newFamilyInformation: {},
      newPersonalReferences: {},
      isCreated: false,
      isCreatedInformal: false,
      isCreatedProfessionalExperience: false,
      isCreatedFamilyInformation: false,
      isCreatedPersonalReferences: false,
      dataLocation: {
        departamentos: [],
        municipios: [],
      },
      // catalogos
      Parentesco: [],
      listaPaises: [],
      listaMunicipios: [],
      listaDepartamentos: [],
      NivelesAcademicos: [],
      listaTipoDocumento: [],
      listaCategoriaLicencia: [],
      id: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    //this.renameFile('20221103 ó Ó Á a IÍÓÚ.pdf')
    if (this.personaId) {
      this.id = this.personaId;
      console.log("ID por parametro de URl", this.personaId);
    } else {
      let personaActiva = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.id = personaActiva.id;
      console.log("ID de usuario activo", personaActiva);
    }
    //this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getcategoriaLicencia();
    await this.getTipoDocumento();
    await this.getvalidateExistence();
    await this.getParentesco();

    this.$isLoading(false);
  },
  methods: {
    ...misMetodos,
    activeTab(id) {
      this.tabs = id;
    },
  },
  computed: {
    personaId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 0.5rem;
  & button {
    font-size: 0.7rem;
  }
}

.validacionEnvio {
  margin: 1rem 0;
}
</style>
